<template>
  <div>
    <div v-show="!detailShow">
      <div class="banner-common" :class="{ 'banner-pc-common': isPc }">
        <img src="../../assets/img/product.png" v-if="!isPc" alt="" />
        <img src="../../assets/img-pc/product-pc.png" v-else alt="" />
        <div class="text">
          <span class="chanese">产品中心</span>
          <span class="english">Product Center</span>
        </div>
      </div>
      <div class="content-erap" :class="{ 'content-wrap-pc': isPc }">
        <van-tabs
          v-model="activeName"
          :class="{ 'van-tabs-pc': isPc }"
          title-active-color="#d4101e"
          title-inactive-color="#333333"
        >
          <van-tab
            :name="item.path"
            v-for="item in tabInfo"
            :key="item.id"
            :title="item.title"
            ><goodsList
              :id="selectedInfo.id"
              :isPc="isPc"
              @toDetails="toDetails"
              :goodsId="id"
          /></van-tab>
        </van-tabs>
      </div>
    </div>

    <!-- 详情 -->
    <div v-if="detailShow">
      <div
        class="banner-common"
        :class="{ 'banner-pc-common': isPc }"
        v-if="isPc"
      >
        <img src="../../assets/img-pc/product-pc.png" alt="" />
        <div class="text">
          <span class="chanese">产品中心</span>
          <span class="english">Product Center</span>
        </div>
      </div>
      <detail @showList="showList" :info="detailInfo" :isPc="isPc" />
    </div>
  </div>
</template>

<script>
import goodsList from "./list";
import detail from "./details";
export default {
  props: ["isPc"],
  name: "product-Info",
  components: {
    goodsList,
    detail
  },
  mounted() {
    this.getTabInfo();
  },
  computed: {
    selectedInfo() {
      let info = {};
      this.tabInfo.length &&
        this.tabInfo.forEach(e => {
          if (this.activeName === e.path) {
            info = e;
          }
        });
      return info;
    }
  },
  data() {
    return {
      activeName: this.$route.query.activeName,
      detailShow: false,
      tabInfo: [],
      detailInfo: {},
      id: this.$route.query.id,
      navigationId: this.$route.query.navigationId
    };
  },
  methods: {
    getTabInfo() {
      const path = this.$route.path;
      let menueList = localStorage.getItem("mobileMenueInfo");
      menueList = menueList ? JSON.parse(menueList) : [];
      menueList.length &&
        menueList.forEach(e => {
          if (e.path === path) {
            this.tabInfo = e.children;
            if (this.navigationId) {
              let info = this.tabInfo.find(e => e.id === this.navigationId);
              if (info) {
                this.activeName = info.path;
              }
            }
          }
        });
    },
    toDetails(item, listData) {
      this.detailInfo = {
        nowInfo: item,
        allInfo: listData
      };
      this.detailShow = true;
    },
    showList() {
      if (this.id) {
        this.$router.push(this.$route.path);
        this.id = "";
        this.navigationId = "";
      }
      this.detailShow = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import "./product.less";
/deep/.van-tab__text.van-tab__text--ellipsis {
  font-weight: 700;
}
</style>
