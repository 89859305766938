<template>
  <div>
    <van-list
      :immediate-check="false"
      v-model="loading"
      :finished="finished"
      finished-text="暂时没有更多了"
      @load="onLoad"
      v-if="!isPc"
    >
      <div class="img-container">
        <div
          class="img-content"
          @click="toDetails(item)"
          v-for="item in listData"
          :key="item.id"
        >
          <div class="img-wrap">
            <img :src="item.mainPicture" alt="" />
          </div>
          <span>{{ item.title }}</span>
        </div>
      </div>
    </van-list>
    <div class="goods-list-pc" v-else>
      <div class="img-container">
        <div
          class="img-content"
          @click="toDetails(item)"
          v-for="item in listData"
          :key="item.id"
        >
          <div class="img-wrap">
            <img :src="item.mainPicture" alt="" />
          </div>
          <span>{{ item.title }}</span>
        </div>
      </div>
      <div class="pagenation-wrap">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- <van-image-preview v-model="previewShow" :images="imageUrls" :startPosition="startPosition">
      <template v-slot:index>{{ previewTitle }}</template>
    </van-image-preview> -->
  </div>
</template>

<script>
import { getFeatureContent } from "@/api/index.js";
export default {
  props: ["id", "goodsId", "isPc"],
  data() {
    return {
      loading: false,
      finished: false,
      currentPage: 1,
      pageNumber: 1,
      pageSize: 8,
      sort: "time",
      order: "asc",
      listData: [],
      finishedText: this.isPc ? "" : "暂时没有更多了",
      total: 0
      //   previewShow: false,
      //   imageUrls: [],
      //   startPosition: 0,
      //   previewTitle: ""
    };
  },

  methods: {
    async onLoad() {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sort: this.sort,
        order: this.order,
        navigationId: this.id
      };
      try {
        const { result } = await getFeatureContent(params);
        const data = result ? result.content : [];
        this.total = result.totalElements;
        if (this.isPc) {
          this.pcHandler(data);
        } else {
          this.mobileHandler(result, data);
        }
      } catch (error) {
        console.log(error);
        this.$message.error("获取内容失败！");
        this.finished = true;
      }
    },
    mobileHandler(result, data) {
      this.listData.push(...data);
      this.loading = false;
      this.pageNumber = this.pageNumber + 1;
      this.goodsId && this.hasIdToDetail(this.goodsId, this.listData);
      if (this.pageNumber > result.totalPages) this.finished = true;
    },
    pcHandler(data) {
      this.listData = data;
      this.goodsId && this.hasIdToDetail(this.goodsId, this.listData);
    },
    hasIdToDetail(id, data) {
      let item = data.find(e => e.id === id);
      this.toDetails(item);
    },
    toDetails(item) {
      this.$emit("toDetails", item, this.listData);
    },
    handleSizeChange() {},
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.onLoad();
    }
  },

  created() {
    this.loading = !this.isPc;
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 36px 47px;
  .img-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .img-wrap {
      width: 501px;
      height: 354px;
      padding: 34px 66px;
      display: flex;
      background-color: RGB(247, 247, 247, 0.2);
      // border-radius: 10px;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
    span {
      font-size: 30px;
      font-weight: bold;
      line-height: 30px;
      text-align: center;
      color: #333333;
      margin: 30px 0;
    }
  }
}
/deep/.van-list__finished-text {
  font-weight: bold;
  color: #999999;
}
</style>
