<template>
  <div :class="isPc ? 'detail-container' : ''">
    <div class="new-detail-wrap" :class="{ 'new-detail-wrap-pc': isPc }">
      <div class="goods-info-remark">
        <div class="title">{{ selctedInfo.title }}</div>
        <div class="content" v-html="selctedInfo.content"></div>
      </div>
      <!-- <div class="text-wrap">
        <h2>产品信息</h2>
        <div class="text">
          <span class="name-title">品名</span>
          <span class="name-detail">财运亨通 环保电子烟花</span>
        </div>
        <div class="text">
          <span class="name-title">类别</span>
          <span class="name-detail">礼炮</span>
        </div>
        <div class="text">
          <span class="name-title">适用</span>
          <span class="name-detail">婚礼庆典、开业店庆、生日宴会等</span>
        </div>
        <div class="text">
          <span class="name-title">尺寸</span>
          <span class="name-detail">40*60*80cm</span>
        </div>
        <div class="text">
          <span class="name-title">数量</span>
          <span class="name-detail">2种款式</span>
        </div>
        <div class="text">
          <span class="name-title">喷射高度</span>
          <span class="name-detail">约7~8米</span>
        </div>
      </div> -->
    </div>
    <div class="control">
      <div class="last" @click="toLast" v-show="nowIndex">上一条</div>
      <div class="back" @click="goLIst">返回</div>
      <div
        class="next"
        @click="toNext"
        v-show="nowIndex !== allInfo.length - 1"
      >
        下一条
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object
    },
    isPc: {
      type: Boolean
    }
  },
  computed: {
    selctedInfo() {
      return this.allInfo[this.nowIndex];
    }
  },
  data() {
    return {
      nowInfo: {},
      allInfo: [],
      nowIndex: 0
    };
  },

  methods: {
    goLIst() {
      this.$emit("showList");
    },
    toLast() {
      this.nowIndex = --this.nowIndex;
      this.toTop();
    },
    toNext() {
      this.nowIndex = ++this.nowIndex;
      this.toTop();
    },
    toTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },
  created() {
    this.nowInfo = this.info.nowInfo;
    this.allInfo = this.info.allInfo;
    this.nowIndex = this.allInfo.findIndex(e => e.id === this.nowInfo.id);
  }
};
</script>

<style lang="less" scoped>
.new-detail-wrap {
  padding: 60px 50px 43px 47px;
  overflow-y: scroll;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /deep/ .title-info {
    text-align: left;
    font-size: 36px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 1px;
    color: #333333;
    margin: 40px 0;
  }
  /deep/.text-wrap {
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    border: solid 2px #f2f2f2;
    line-height: 100px;
    .text {
      height: 100px;
      display: flex;
      font-size: 30px;
      justify-content: space-between;
      border-bottom: solid 2px #f2f2f2;
      &:last-child {
        border-bottom: none;
      }
      .name-title {
        width: 260px;
        text-align: left;
        border-right: 1px solid #f2f2f2;
        padding-left: 50px;
        color: #999999;
      }
      .name-detail {
        flex: 1;
        text-align: left;
        padding-left: 50px;
      }
    }
  }
  .title {
    font-size: 48px;
    font-weight: bold;
    line-height: 60px;
    color: #1d1b1b;
    margin-bottom: 50px;
  }
  .time-wrap {
    margin: 32px 0;
    font-size: 30px;
    line-height: 26px;
    color: #adadad;
    .number {
      margin-left: 90px;
    }
  }
  .text {
    /deep/p,
    /deep/div {
      text-align: left;
      line-height: 72px;
      letter-spacing: 1px;
      color: #333333;
    }
  }
}
.control {
  padding: 30px 50px 30px 47px;
  background-color: #fff;
  width: 100%;
  z-index: 100;
  line-height: 72px;
  letter-spacing: 1px;
  color: #999999;
  position: fixed;
  bottom: 0;
  .last {
    float: left;
    font-size: 30px;
  }
  .next {
    font-size: 30px;
    float: right;
  }
  .back {
    font-size: 30px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
